


























































import TopBar from "@/components/common/TopBar.vue";
import StaticTable from "@/components/table/StaticTable.vue";
import Widget from "@/components/common/Widget.vue";
import VerticalLineAndBarSingleChart from "@/components/charts_js/VerticalLineAndBarSingleChart.vue";
import VerticalLineAndBarGroupChart from "@/components/charts_js/VerticalLineAndBarGroupChart.vue";
import Vue from "vue";
import Component from "vue-class-component";
import store from "../../store";
import { Watch } from "vue-property-decorator";
import {
  combineMonthToQuarter,
  chartVerticalGroupBar,
  monthChart,
} from "../../helper/charts";
import { callKunden, callPrevKunden, callCalc } from "../../helper/utils";

@Component({
  components: {
    TopBar,
    Widget,
    StaticTable,
    VerticalLineAndBarSingleChart,
    VerticalLineAndBarGroupChart,
  },
})
export default class Kundenanalyse extends Vue {
  initPage = false;
  data: { d0: any; d1: any } = { d0: {}, d1: {} };

  get kunden() {
    let kundenHead: any = [];
    store.watch(
      (getter) => {
        if (
          getter.prevUmsDetailKunden &&
          (getter.prevUmsDetailKunden as any).kontotable.length > 0
        ) {
          kundenHead = [
            { title: "Bereich", class: "vertical-align-middle" },
            {
              title: "Werte",
              description:
                store.getters.selectedYear && store.getters.selectedYear[0],
              class: "text-center",
            },
            {
              title: "Vergleich",
              description:
                store.getters.selectedYear && store.getters.selectedYear[0] - 1,
              class: "text-center",
            },
            {
              title: "Abweichung",
              description: "Ist/Referenz",
              class: "text-center",
              colspan: 2,
            },
          ];
        } else {
          kundenHead = [
            { title: "Bereich", class: "vertical-align-middle" },
            {
              title: "Werte",
              description:
                store.getters.selectedYear && store.getters.selectedYear[0],
              class: "text-center",
            },
          ];
        }
      },
      (getters) => {}
    );
    return {
      head: kundenHead,
      body: {
        prev:
          store.getters.prevUmsDetailKunden &&
          store.getters.prevUmsDetailKunden.kontotable,
        current:
          store.getters.umsDetailKunden &&
          store.getters.umsDetailKunden.kontotable,
      },
    };
  }

  get umsKunden() {
    return {
      prev: store.getters.prevUmsDetailKunden,
      cur: store.getters.umsDetailKunden,
      resize: store.getters.resize,
    };
  }

  get topBarData() {
    return {
      year: store.getters.selectedYear,
      client: store.getters.selectedClient,
      rangeStep: store.getters.rangeStep,
    };
  }

  get topBarNavigation() {
    return {
      year: store.getters.selectedYear,
      client: store.getters.selectedClient,
      rangeStep: store.getters.rangeStep,
    };
  }

  get biAgency() {
    return store.getters.selectedBiAgency;
  }

  pushStateHistory(stb: any, mandant: any, year: any, range: any) {
    const selectedStb = this.biAgency != null ? this.biAgency : stb;

    let path =
      "/" +
      this.$route.name +
      "/" +
      encodeURIComponent(selectedStb) +
      "/" +
      encodeURIComponent(mandant) +
      "/" +
      encodeURIComponent(year) +
      "/" +
      encodeURIComponent(range);

    if (this.$route.path !== path) {
      this.$router.replace(path);
    }
  }

  getFilterParameter(filterKunden: any) {
    let ids: any = [];
    let params: any = {};

    if (filterKunden == null) {
      return params;
    }
    filterKunden.forEach((v: any) => {
      ids.push(v.value[0]);
    });
    if (ids.length > 0) {
      params["filter"] = ids;
    }
    return params;
  }

  @Watch("umsKunden")
  onDataChange() {
    const data0 = (this.umsKunden.cur && this.umsKunden.cur.monthtable) || [];
    const data1 = (this.umsKunden.prev && this.umsKunden.prev.monthtable) || [];

    if (!store.getters.selectedYear) {
      return;
    }
    var d0: any = [];
    var d1: any = [];
    for (var i = 1; i < 13; i++) {
      var key = store.getters.selectedYear[0] + "-" + (i < 10 ? "0" + i : i);
      var d0_data = 0;
      var d1_data = 0;
      if (data0[i] != undefined) {
        d0_data = data0[i];
      }
      if (data1[i] != undefined) {
        d1_data = data1[i];
      }
      d0[key] = d0_data;
      d1[key] = d1_data;
    }
    if (store.getters.rangeStep === "quarterly") {
      d0 = combineMonthToQuarter(d0);
      d1 = combineMonthToQuarter(d1);
    }

    this.data = { d0: d0, d1: d1 };

    // monthChart(
    //   (this.umsKunden.cur && this.umsKunden.cur.monthtable) || [],
    //   (this.umsKunden.prev && this.umsKunden.prev.monthtable) || [],
    //   "Umsatzerlöse",
    //   "Umsatzerlöse (Vorjahr)",
    //   "chart_uk_monate"
    // );
  }

  @Watch("topBarData")
  onTopBarDataChange() {
    if (this.topBarData.year && this.topBarData.client) {
      // force to update if range is changed
      let params: any = {};

      // get parameters based on filter kunden
      params = this.getFilterParameter(store.getters.filterKunden);

      // if (this.topBarData.rangeStep) {
      //   store.dispatch("updateIsKundenCalled", false);
      //   store.dispatch("updateIsPrevKundenCalled", false);
      //   store.dispatch("updateIsCalcCalled", false);
      // }

      if (!store.getters.isKundenCalled) {
        callKunden(params);
      }
      if (!store.getters.isPrevKundenCalled) {
        callPrevKunden(params);
      }
      if (!store.getters.isCalcCalled) {
        callCalc();
      }

      if (this.initPage) {
        this.onTopBarNavigationChange();
        this.initPage = false;
      }
    }
  }

  @Watch("topBarNavigation")
  onTopBarNavigationChange() {
    // change URL based on seleted parameters in top bar

    let mandant: string = this.topBarNavigation.client[1];
    let year: string = this.topBarNavigation.year[0];
    let stb: string =
      this.topBarNavigation.client && this.topBarNavigation.client[0];

    this.pushStateHistory(stb, mandant, year, store.getters.rangeStep);
  }

  mounted() {
    this.initPage = true;
    this.onDataChange();
    this.onTopBarDataChange();
    if (localStorage.getItem("from") == "kunden_table") {
      (document.getElementById("kunden_table") as any).scrollIntoView();
      localStorage.removeItem("from");
    }
  }
}
